import React, {useEffect, useState} from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Question from '../components/Question';

const MineSporsmal = props => {
    const { data } = props.data;

    const [list, setList] = useState([]);

    const title = "Mine spørsmål";

    let questions = [];

    useEffect(() => {
        if (typeof window !== `undefined`) {
            questions = JSON.parse(localStorage.getItem('sporsmal'));
            setList(questions)
        }
    }, [])

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const stringList = JSON.stringify(list);
            localStorage.setItem('sporsmal', stringList);
        }
    }, [list])

    return (
      <Layout showRelated={true}>
        <SEO
            description="Mange andre quizer er tilgjengelig gratis på quizbladet.no."
            title={title} />
        <div className="quiz">
          {<div className="justify-content-start pb-2">
            <div className="pt-6">
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
          </div>}
          <div>
            {list && list.map((it, index) => {
              return (
                <Question setList={setList} list={list} index={index+1} data={it} addBtn={false} removeBtn={true}/>
              )})}
          </div>
        </div>
      </Layout>
  );
};

export const query = graphql`
  query {
    data: markdownRemark(fileAbsolutePath: {regex: "/(MineSporsmal.md)/"}) {
      html
    }
  }
`;

export default MineSporsmal;
